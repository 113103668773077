import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout.js';

export default function AboutPage() {
    return (
        <Layout
            title="About DefiYieldCalculator.com"
            description='DefiYieldCalculator.com - the best calculator for calculating defi staking rewards over time'
        >
            <h1>DefiYieldCalculator.com</h1>
            <p>My name is Donald, aka <a href="https://defibuilder.eth.link/">DefiBuilder.eth</a></p>
            <p>I spent 4 years studying Economics and Business Enterprise at university. Then I decided to follow my curiosity down an unusual path, playing online poker for nearly 10 years. During this journey, I founded a coaching team which became the most successful of its kind in the world.</p>
            <p>I also developed a passion for analysing economic decision making under uncertainty. This naturally led me to the world of cryptocurrency. </p>
            <p>I bought my first Bitcoin in 2015, and quit poker to try and contribute something to the space in April 2021.</p>
            <p>I took a 4 month coding bootcamp, and made a basic ERC20 token and staking interface for my capstone project. Since then, I've been looking for situations where I can use my coding skills to solve problems. </p>
            <p>I created this site after searching for an online calculator that accurately models crypto pool 2s. </p>
            <p>I cloned the best impermanent loss calculator I could find (<a href="https://defiyield.app/advanced-impermanent-loss-calculator/">this one</a>, from the good folks at <a href="https://defiyield.app/">DefiYield.app</a>). Then I added staking rewards, and translated the whole thing into this website.</p>
            <p>If you want to hear more from me, you can follow me on <a href="https://twitter.com/DefiBuilderETH">Twitter</a>.</p>
        </Layout>
    )
}